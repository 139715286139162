import React, { SVGAttributes } from 'react';
import { useThemeContext } from '@oberoninternal/travelbase-ds/hooks/useThemeContext';

const ArrowRightAlt = (props: SVGAttributes<SVGElement>) => {
    const theme = useThemeContext();
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5.3999 1.79999L6.8999 3.29999L7.47988 3.87997L8.5999 4.99999L5.3999 8.19999"
                stroke={theme.colors.primary[80]}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.41211 4.99999H7.79968"
                stroke={theme.colors.primary[80]}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
export default ArrowRightAlt;
